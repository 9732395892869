<template>
  <section class="faq">
    <div class="faq__content constrain-content center">
      <h2 class="margin-bottom--large">FAQ</h2>

        <b-collapse
            class="card center"
            animation="slide"
            @open="isOpen='how-to'"
            :open="isOpen=='how-to'"
            >
            <template #trigger="props">
                <div class="card-header" role="button">
                    <p class="card-header-title">How does it work?</p>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <ul>
                        <li>1. You'll have a first chat with Ebba for her to get to know your background, thoughts and needs on your next career move.</li>
                        <li>2. You'll be connected with a career agent for a personal digital meeting.</li>
                        <li>3. Your career agent will match you with exciting companies and give you support while you move forward in your career journey.</li>
                        <li>4. Sign your dream job!</li>
                    </ul>
                </div>
            </div>
        </b-collapse>
        <b-collapse
            class="card center"
            animation="slide"
            @open="isOpen='career-agent'"
            :open="isOpen=='career-agent'"
            >
            <template #trigger="props">
                <div class="card-header" role="button">
                    <p class="card-header-title">Why do I need a career agent?</p>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <p>A tech career agent is representing YOU. We will together with you build a long lasting two-way relationship where we get to know your personality and what kind of positions and companies that will catch your interest.</p>
                    <p>Together we identify your passion and purpose. We will give you short alerts about what’s going on in the tech industry and inspiration regarding interesting tech companies, even when you are not looking. You might already have a dream company or are just curious about a specific tech branch. We are here to help you out. </p>
                </div>
            </div>
        </b-collapse>
        <b-collapse
            class="card center"
            animation="slide"
            @open="isOpen='about'"
            :open="isOpen=='about'"
            >
            <template #trigger="props">
                <div class="card-header" role="button">
                    <p class="card-header-title">Who are we?</p>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <p>Ebba is developed and maintained by the Gothenburg based agencies Techpie and Your Monday.  Our career agents have deep knowledge in tech recruitment and headhunting, focusing on all related positions to startup, product- and consultant companies.</p>
                </div>
            </div>
        </b-collapse>
        <b-collapse
            class="card center"
            animation="slide"
            @open="isOpen='data'"
            :open="isOpen=='data'"
            >
            <template #trigger="props">
                <div class="card-header" role="button">
                    <p class="card-header-title">How do we treat your data?</p>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                    <p>We value and protect the privacy of our users, and we are dedicated to be as transparent as possible regarding the use of your personal data.</p>
                    <p>The data we collect is mainly to reach out and deliver a great service to you — we will never sign you up for any newsletter or exploit the data in any way not clearly communicated. Our Privacy Policy explains in detail how we collect and use your personal data.</p>
                </div>
            </div>
        </b-collapse>
    </div>
  </section>
</template>
<script>
export default {
  components: {
  },
    data() {
        return {
            isOpen: '',
        }
    },
  mounted() {},
};
</script>
<style lang="scss">
.collapse {
    width: 100%;
    max-width: 500px;
    border-bottom: 2px solid #e7e7e7;
    padding: 2.5rem 0 1.5rem;
    .card-header-title {
        font-weight: 600;
        font-size: 1.35rem;
        text-align: left;

    }
    &-content {
        text-align: left;
    }
}
</style>
